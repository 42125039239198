import { locales as toolkitLocales } from '@yes.technology/react-toolkit'
import deepmerge from 'deepmerge'

import enUS from './en-US.json'
import ptBR from './pt-BR.json'
import deDE from './de-DE.json'

export const defaultLanguage = 'en-US'

const locales = {
  'en-US': deepmerge(toolkitLocales['en-US'], enUS),
  'pt-BR': deepmerge(toolkitLocales['pt-BR'], ptBR),
  'de-DE': deepmerge(toolkitLocales['de-DE'], deDE)
}

export default locales
