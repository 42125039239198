import { AreaLine, Button } from '@yes.technology/react-toolkit'
import styled from 'styled-components'

export const StyledAreaLine = styled(AreaLine).attrs({
  alignment: 'center',
  heightClass: 'small',
  placement: 'sticky-top'
})`
  margin-bottom: 24px;
  background-color: #004099;
  z-index: 9998; // Temp fix to render behind Feedback Card
  
  > div > div {
      gap: 16px;
  }

  a {
    text-decoration: none;
  }
`

export const StyledButton = styled(Button).attrs({
  impactClass: 'navigation',
  variant: 'secondary',
  heightClass: 'semiSmall'
})`
  width: 100px;
  font-weight: 600;
  font-size: 14px;
  color: #006BFF;
`
